var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"930px","height":"500px","max-height":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"dark":_vm.setDark,"color":'transparent',"overlap":"","dense":""}},[(_vm.check)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2 vcad-btn",attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){return _vm.deleteFilter()}}},on),[_c('v-icon',[_vm._v(" mdi-filter-remove ")])],1)]}}],null,true)},[_c('span',[_vm._v("Clear filters")])]):_vm._e(),_c('v-btn',_vm._g({staticClass:"mr-1 elevation-2 ml-1 vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){_vm.showInfoData = true}}},on),[_vm._v("Filters "),(!_vm.check)?_c('v-icon',[_vm._v(" mdi-filter-plus-outline")]):_c('v-icon',[_vm._v("mdi-filter-plus")])],1)],1)]}}]),model:{value:(_vm.showInfoData),callback:function ($$v) {_vm.showInfoData=$$v},expression:"showInfoData"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',{attrs:{"dense":""}},[_c('v-icon',[_vm._v("mdi-filter-plus")]),_c('span',{staticClass:"pl-1"},[_vm._v("Filters")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.forceRerender();
            _vm.showInfoData = false;}}},[_c('v-icon',[_vm._v("mdi-close")]),_c('span')],1)],1),_c('v-container',[_c('v-row',{attrs:{"align":"center","dense":"","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"dense":"","cols":"6"}},[_c('v-select',{ref:"select",attrs:{"items":_vm.itemStatus,"filled":"","dense":"","height":"100","clearable":"","outlined":"","menu-props":{
                top: true,
                offsetY: true,
                maxHeight: 304,
                maxWidth: 300,
              },"rounded":"","label":"Status","item-text":"txt","item-value":"statusId","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleStatus}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.iconStatus)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.$refs.select.blur()}}},[_vm._v("mdi-close")])],1),_c('v-divider',{staticClass:"mt-2"})],1)]},proxy:true},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"mb-1 mt-1",attrs:{"small":""}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"color":data.item.color}},[_vm._v(_vm._s(data.item.ico))])],1),_vm._v(" "+_vm._s(data.item.txt)+" ")],1)]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"dense":"","cols":"6"}},[_c('v-select',{ref:"select1",attrs:{"items":_vm.extensions,"filled":"","dense":"","height":"100","clearable":"","outlined":"","menu-props":{
                top: true,
                offsetY: true,
                maxHeight: 304,
                maxWidth: 300,
              },"rounded":"","label":"Type","item-text":"text","item-value":"value","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleExt}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.iconExtension)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.$refs.select1.blur()}}},[_vm._v("mdi-close")])],1),_c('v-divider',{staticClass:"mt-2"})],1)]},proxy:true},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"mb-1 mt-1",attrs:{"small":""}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('img',{attrs:{"src":_vm.getExtensionIcon(data.item.value),"alt":""}})]),_vm._v(" "+_vm._s(data.item.value)+" ")],1)]}}]),model:{value:(_vm.extensionsFilter),callback:function ($$v) {_vm.extensionsFilter=$$v},expression:"extensionsFilter"}})],1)],1),_c('v-row',{attrs:{"align":"center","dense":"","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"dense":"","cols":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"bottom":"","origin":"center center","transition":"slide-y-transition","max-width":"290px","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"filled":"","dense":"","label":"Conversion date","hint":"From ~ To","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"dense":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false;
                    _vm.date = [];}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"dense":"","cols":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Range size : "),_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.sizeFilterString.join(" - ")))])]),_c('v-range-slider',{attrs:{"thumb-label":"always","dense":"","thumb-size":"14","min":"0","max":"3e+9"},scopedSlots:_vm._u([{key:"thumb-label",fn:function(){return undefined},proxy:true}]),model:{value:(_vm.sizeFilter),callback:function ($$v) {_vm.sizeFilter=$$v},expression:"sizeFilter"}})],1)],1),_c('v-row',{attrs:{"align":"center","dense":"","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"dense":"","cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":"File ID","hint":"Search files for file id (partial search)","persistent-hint":"","prepend-icon":"mdi-file-key"},model:{value:(_vm.idFileSearch),callback:function ($$v) {_vm.idFileSearch=$$v},expression:"idFileSearch"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"vcad-btn ml-4",attrs:{"small":"","color":"secondary","outlined":""},on:{"click":function($event){return _vm.deleteFilter()}}},[_c('v-icon',[_vm._v("mdi-filter-remove")]),_c('span',{staticClass:"ml-1"},[_vm._v("Clear filters")])],1),_c('v-btn',{staticClass:"vcad-btn ml-2",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateValue()}}},[_c('v-icon',[_vm._v("mdi-filter")]),_c('span',{staticClass:"ml-1"},[_vm._v("Apply")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }