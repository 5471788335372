var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",staticStyle:{"margin-bottom":"39.167px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[(_vm.headers)?_c('v-data-table',{staticClass:"elevation-1 vcad-table mx-16",attrs:{"headers":_vm.headers,"items":_vm.checkFilterVar ? _vm.fileListFiltered : _vm.rows,"pagination.sync":"pagination","options":_vm.tableOptions,"search":_vm.tableFilter,"item-key":"slotKey","loading":_vm.isLoading,"show-expand":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary lighten-2 vcad-table-top"},[_c('v-row',{staticClass:"align-center "},[_c('v-col',{attrs:{"cols":8}},[_c('h3',{staticClass:"white--text"},[_vm._v("Vcad slots")])]),_c('v-col',{staticClass:"flex-row d-flex",attrs:{"cols":4}},[_c('file-list-filter',{attrs:{"items":_vm.rows},on:{"filterApply":_vm.checkFilter},model:{value:(_vm.fileListFiltered),callback:function ($$v) {_vm.fileListFiltered=$$v},expression:"fileListFiltered"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","clearable":"","solo":"","dense":""},model:{value:(_vm.tableFilter),callback:function ($$v) {_vm.tableFilter=$$v},expression:"tableFilter"}})],1)],1)],1)]},proxy:true},{key:"item.dateStartConv",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dateStartConv))])]}},{key:"item.statusObj",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(_vm._s(item.statusObj.txt))]),_c('v-icon',{staticClass:"mx-2",attrs:{"color":item.statusObj.color}},[_vm._v(_vm._s(item.statusObj.ico))])],1)]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},on),[_vm._v(_vm._s(item.error))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 my-5",staticStyle:{"border":"solid 1px rgba(0, 0, 0, 0.38)"},attrs:{"headers":_vm.expansionTableHeaders,"items":item.groupedList,"item-key":"id","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[(JSON.parse(item.info).b360Item)?_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setModelAndShow(item)}}},[_vm._v("View")]):_vm._e(),(item.status == 2 && JSON.parse(item.info).b360Item)?_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showTemplateList(item)}}},[_vm._v("TEMPLATES")]):_vm._e(),_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){_vm.setCurrentItem(item);
                      _vm.ShowDeleteDialog = true;}}},[_vm._v("CLEAR SLOT")])],1)]}}],null,true)})],1)]}}],null,true)}):_vm._e()],1)],1),_c('vcad-dialog',{attrs:{"title":"Clear slot","contentProps":{ fileName: _vm.currentItem.name }},scopedSlots:_vm._u([{key:"content",fn:function(ref){
                      var contentData = ref.contentData;
return [_c('p',{staticClass:"text-center"},[_vm._v(" You are about to clear the selected slot from the current loaded model : "),_c('i',[_c('b',[_vm._v(_vm._s(contentData.fileName))])])]),_c('p',{staticClass:"text-center"},[_vm._v(" Previously generated reports may not work correctly. Are you sure you wish to proceed? ")])]}},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-end",staticStyle:{"width":"100%","height":"100%"}},[_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":""},on:{"click":function($event){_vm.ShowDeleteDialog = false}}},[_vm._v("CLOSE")]),_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.clearSlot(_vm.currentItem)}}},[_vm._v("CONFIRM")])],1)]},proxy:true}]),model:{value:(_vm.ShowDeleteDialog),callback:function ($$v) {_vm.ShowDeleteDialog=$$v},expression:"ShowDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }