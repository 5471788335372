<template>
  <v-container fluid class="fill-height" style="margin-bottom: 39.167px">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="checkFilterVar ? fileListFiltered : rows"
          class="elevation-1 vcad-table mx-16"
          pagination.sync="pagination"
          :options.sync="tableOptions"
          :search="tableFilter"
          item-key="slotKey"
          v-if="headers"
          :loading="isLoading"
          show-expand
        >
          <template v-slot:top>
            <div class="primary lighten-2 vcad-table-top">
              <v-row class="align-center ">
                <v-col :cols="8">
                  <h3 class="white--text">Vcad slots</h3>
                </v-col>
                <!-- <v-spacer class="col-6"></v-spacer> -->
                <v-col class="flex-row d-flex" :cols="4">
                  <file-list-filter
                    :items="rows"
                    @filterApply="checkFilter"
                    v-model="fileListFiltered"
                  />

                  <!-- SEARCH -->
                  <v-text-field
                    v-model="tableFilter"
                    append-icon="mdi-magnify"
                    label="Search"
                    hide-details
                    clearable
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </template>

          <!-- IMPORT DATE COL -->
          <template v-slot:[`item.dateStartConv`]="{ item }">
            <span>{{ item.dateStartConv }}</span>
          </template>

          <!-- STATUS COL -->
          <template v-slot:[`item.statusObj`]="{ item }">
            <span
              class="d-inline-flex justify-space-between"
              style="width: 100%"
            >
              <span>{{ item.statusObj.txt }}</span>
              <v-icon :color="item.statusObj.color" class="mx-2">{{
                item.statusObj.ico
              }}</v-icon>
            </span>
          </template>

          <!-- ERROR COL -->
          <template v-slot:[`item.error`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 250px"
                  v-on="on"
                  >{{ item.error }}</span
                >
              </template>
              <span>{{ item.error }}</span>
            </v-tooltip>
          </template>

          <!-- ACTION COL -->
          <!-- <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex align-center justify-center"> -->

          <!-- DELETE -->
          <!-- <v-btn
                  outlined
                  small
                  color="primary"
                  class="vcad-btn"
                  @click=" setCurrentItem(item); ShowDeleteDialog = true"
                  >CLEAR SLOT</v-btn> -->
          <!-- 
              </div>
            </template> -->

          <!-- EXPANSION -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="expansionTableHeaders"
                :items="item.groupedList"
                item-key="id"
                class="elevation-0 my-5"
                style="border: solid 1px rgba(0, 0, 0, 0.38)"
                dense
              >
                <!-- ACTIONS -->
                <template v-slot:[`item.action`]="{ item }">
                  <div class="d-flex align-center justify-center">
                    <!-- PREVIEW -->
                    <v-btn
                      outlined
                      small
                      color="primary"
                      class="vcad-btn"
                      @click="setModelAndShow(item)"
                      v-if="JSON.parse(item.info).b360Item"
                      >View</v-btn
                    >

                    <!-- TEMPLATE LIST -->
                    <v-btn
                      outlined
                      small
                      color="primary"
                      class="vcad-btn"
                      @click="showTemplateList(item)"
                      v-if="item.status == 2 && JSON.parse(item.info).b360Item"
                      >TEMPLATES</v-btn
                    >

                    <!-- DELETE -->
                    <v-btn
                      outlined
                      small
                      color="primary"
                      class="vcad-btn"
                      @click="
                        setCurrentItem(item);
                        ShowDeleteDialog = true;
                      "
                      >CLEAR SLOT</v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- DELETE DIALOG -->
    <vcad-dialog
      v-model="ShowDeleteDialog"
      title="Clear slot"
      :contentProps="{ fileName: currentItem.name }"
    >
      <template v-slot:content="{ contentData }">
        <p class="text-center">
          You are about to clear the selected slot from the current loaded model
          :
          <i
            ><b>{{ contentData.fileName }}</b></i
          >
        </p>
        <p class="text-center">
          Previously generated reports may not work correctly. Are you sure you
          wish to proceed?
        </p>
      </template>

      <template v-slot:actions>
        <div
          class="d-inline-flex justify-end"
          style="width: 100%; height: 100%"
        >
          <v-btn
            outlined
            small
            color=""
            class="vcad-btn"
            @click="ShowDeleteDialog = false"
            >CLOSE</v-btn
          >
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn"
            @click="clearSlot(currentItem)"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </vcad-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { vcadFileStatusDict } from "../utils/VCadItemStatusUtil";
import FileListFilter from "./FileListFilter.vue";
import VcadDialog from "./VcadDialog";

export default {
  components: {
    "vcad-dialog": VcadDialog,
    FileListFilter,
  },
  data() {
    return {
      fileListFiltered: [],
      checkFilterVar: false,
      headers: [],
      tableFilter: "",
      ShowDeleteDialog: false,
      currentItem: {},
      isLoading: false,
      expansionTableHeaders: [],
    };
  },
  computed: {
    ...mapGetters(["vcadFileList", "accountId", "selectedProject"]),
    rows() {
      // let rows = this.vcadFileList.map((row) => {
      //   // Set Status Object
      //   row.statusObj = vcadFileStatusDict[row.status];
      //   return row;
      // });

      if (!this.vcadFileList || this.vcadFileList.length == 0) {
        return [];
      }

      // Map missing data
      let fixedKeys = this.vcadFileList.map((item) => {
        item.slotKey = item.slotKey || item.id;
        item.statusObj = vcadFileStatusDict[item.status];

        const info = JSON.parse(item.info);
        item.versionDescr = info.b360Item
          ? "V " + info.b360Item.revisionDisplayLabel
          : null;
        item.typeDescr = info.b360Item ? info.b360Item.type : null;

        //TODO: Gestire un tipo che determini se si ta guardando una fase, versione o view

        // Format import date
        let tmpDateConv = new Date(item.dateStartConv);
        let options = {
          dateStyle: "medium",
          timeStyle: "short",
          hour12: false,
        };
        item.dateStartConv = tmpDateConv.toLocaleString(
          window.navigator.language,
          options
        );

        // Add folder name and import user
        item.folderName = info.b360Item.folderName;
        item.importUser = info.email;

        return item;
      });

      // Create row dictionary in form { *slotKey* : {*rowData*} }
      let rowDictionary = fixedKeys.reduce((dict, curr) => {
        // If missing, create key item
        if (!dict[curr.slotKey]) {
          dict[curr.slotKey] = {
            name: JSON.parse(curr.info).fileName,
            label: curr.label,
            dateStartConv: curr.dateStartConv,
            statusObj: curr.statusObj,
            error: curr.error,
            slotKey: curr.slotKey,
            groupedList: [],
            folderName: curr.folderName,
            importUser: curr.importUser,
          };
        }

        // Add to gouped item list
        dict[curr.slotKey].groupedList.push(curr);
        return dict;
      }, {});

      // Convert dictionary to array of rows
      let rows = Object.values(rowDictionary)
        // Set number of items in expansion
        .map((row) => {
          row.nbrItems = row.groupedList.length;
          return row;
        });

      return rows;
    },
    tableOptions: {
      get() {
        let screenSize = this.$vuetify.breakpoint.name;
        return {
          itemsPerPage: screenSize == "xl" ? 10 : 5,
        };
      },
      set() {},
    },
  },
  watch: {
    // Whatch initial seleciton of user account
    accountId(to) {
      if (to) {
        // Load file list
        this.loadFiles();
      }
    },
  },
  methods: {
    ...mapActions([
      "setVcadFileList",
      "setFileData",
      "setShowPreview",
      "deleteVcadFile",
      "fetchNodes",
      "setVcadTemplateList",
      "setShowTemplates",
      "setCurrentViews",
      // "setCurrentViewsFromUrn",
      // "setCurrentViewFromB360DocumentVersion",
      // "retrieveDerivativeUrnFromDocVersion"
    ]),
    /**
     * Set the current model and show preview.
     */
    async setModelAndShow(vcadFileInfo) {
      let payloadData = JSON.parse(vcadFileInfo.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;

      await this.setFileData({ slot: 1, data: payloadData });
      // .then( (fd) => {
      //   this.setCurrentViews(fd.b360Item)
      // });
      await this.setShowPreview({ show: true, mode: 0 });
    },
    /**
     * Set the values of the currentItem for deletion
     */
    setCurrentItem(item) {
      this.currentItem = Object.assign({}, item);
    },
    /**
     * Delete file in slot and clear slot for new use
     */
    clearSlot(vcadFileData) {
      this.deleteVcadFile({ guid: vcadFileData.id })
        .then(() => {
          this.ShowDeleteDialog = false;
          this.currentItem = Object.assign({});
          this.loadFiles();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Display list of templates for download
     */
    async showTemplateList(vcadFileInfo) {
      let vcadType = vcadFileInfo.type;
      let payloadData = JSON.parse(vcadFileInfo.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;

      // Concurrent promise execution
      let p0 = this.setFileData({ slot: 1, data: payloadData }).then((fd) => {
        this.setCurrentViews(fd.b360Item);
      });
      let p1 = this.setVcadTemplateList(vcadType);
      let p2 = this.setShowTemplates(true);
      await Promise.all([p0, p1, p2])
        .then(() => console.log("resolved all promises"))
        .catch((err) => console.error("My error: ", err));
    },
    /**
     * Load Vcad files.
     */
    loadFiles() {
      this.isLoading = true;
      this.setVcadFileList()
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkFilter(value) {
      this.checkFilterVar = value;
    },
  },
  mounted() {
    this.loadFiles();

    // Setup table headers
    this.headers = [
      // {
      //   text: "Slot ID",
      //   value: "id",
      //   align: "center",
      //   sortable: true,
      //   filterable: true,
      //   divider: true,
      // },
      {
        text: "File name",
        value: "name",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Label",
        value: "label",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "View name",
        value: "viewableName",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Import date",
        value: "dateStartConv",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
        sort: (a, b) => (new Date(a) >= new Date(b) ? 1 : -1),
      },
      {
        text: "Status",
        value: "statusObj",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
        sort: (a, b) => (a.txt >= b.txt ? 1 : -1),
      },
      //   {
      //     text: "File size",
      //     value: "fileSize",
      //     align: "center",
      //     sortable: true,
      //     filterable: true,
      //     divider: true,
      //   },
      {
        text: "Error message",
        value: "error",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "No. items",
        value: "nbrItems",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Folder name",
        value: "folderName",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Actions",
        value: "action",
        align: "center",
        sortable: false,
        filterable: false,
        divider: true,
      },
    ];

    // Setup table header for expansion table
    this.expansionTableHeaders = [
      {
        text: "File name",
        value: "name",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "View name",
        value: "viewableName",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Version",
        value: "versionDescr",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Item type",
        value: "typeDescr",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Import user",
        value: "importUser",
        align: "center",
        sortable: true,
        filterable: true,
        divider: true,
      },
      {
        text: "Actions",
        value: "action",
        align: "center",
        sortable: false,
        filterable: false,
        divider: true,
      },
    ];
  },
};
</script>

<style lang="css" scoped>
.vcad-table-top {
  width: 100%;
  padding: 0 1em;
  margin-bottom: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.vcad-table td {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}
</style>